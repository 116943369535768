// @ts-nocheck
import { Component, Vue, Watch, Mixins, Ref } from 'vue-property-decorator';
import 'ol/ol.css';
import {
    Map,
    View,
    Feature,
} from 'ol';
import {
    GPX,
} from 'ol/format';
import {
    OSM,
    Vector as VectorSourceIncomplete
} from 'ol/source';
import {
    Tile as TileLayer,
    Vector as VectorLayer,

} from 'ol/layer';
import {
    Circle as CircleStyle,
    Fill,
    Stroke,
    Style,
    Icon,
    Text,
} from 'ol/style';
import {
    Polygon,
    Point,
} from 'ol/geom';
import {Select} from 'ol/interaction';
import {click as Click} from 'ol/events/condition';
import {circular} from 'ol/geom/Polygon';
import * as Extent from 'ol/extent';
import {fromLonLat} from 'ol/proj';
import Control from 'ol/control/Control';

declare global {
  interface Window {
    // hoverFeatureId: string;
    highlightedFeature: any;
  }
}
class VectorSource extends VectorSourceIncomplete{
  featuresRtree_!: {
    items_: {
      [key: number]: unknown;
    };
  }
}
const uriTest = /\w+:(\/?\/?)[^\s]+/

const style = {
  'Point': new Style({
    image: new CircleStyle({
      fill: new Fill({
        color: 'rgba(255,255,0,0.4)',
      }),
      radius: 5,
      stroke: new Stroke({
        color: '#ff0',
        width: 1,
      }),
    }),
  }),
  'LineString': new Style({
    stroke: new Stroke({
      color: '#f00',
      width: 3,
    }),
  }),
  // 'LinearRing': new Style({
  //   stroke: new Stroke({
  //     color: '#f00',
  //     width: 3,
  //   }),
  // }),
  'MultiLineString': new Style({
    stroke: new Stroke({
      color: '#f00',
      width: 3,
    }),
  }),
};

// const tracks: {
//   name: string;
//   featureId: string;
// }[] = [];
const nullTrack: Track = {
  name: 'Error',
  difficulty: 'none',
  gpx: '',
}
const colors = {
  white: '#fff',
  none: '#888',
  black: '#111',
  red: '#be1622',
  blue: '#2c4b9b',
  green: '#006633',
  grey: '#3c3c3b',
  yellow: '#fcea10',
}

const colorFills: {
  [key: string]: Fill;
} = {};

for (const [colorName,color] of Object.entries(colors)){
  colorFills[colorName] = new Fill({color});
}

const trackStyles: {
  [key: string]: Style;
} = {};

for (const [colorName,color] of Object.entries(colors)){
  trackStyles[colorName] = new Style({
    stroke: new Stroke({
      color,
      width: 3,
    })
  });
}

const markerIcons: {
  [key: string]: Icon;
} = {};

for (const [colorName,color] of Object.entries(colors)){
  markerIcons[colorName] = new Icon({
    anchor: [0.5, 0.9],
    opacity: 1,
    src: require('../assets/marker.svg'),
    scale: 0.5,
    color,
  })
}
const markerTextStroke = new Stroke({
  color: "#eee",
  width: 1
});

const locationStyle = new Style({
  image: new CircleStyle({
    fill: new Fill({
      color: colors['blue'],
    }),
    radius: 15,
    stroke: new Stroke({
      color: '#fff',
      width: 2,
    }),
  }),
}),
// var marker = new Feature({
//   geometry: new Point(proj.fromLonLat([lon, lat])),
// });
// console.log(source.featuresRtree_.items_);

// source.addFeature(new Feature({
//   geometry: new Polygon([]),
//   labelPoint: new Point(fromLonLat([10, 60.2])),
//   name: 'My Polygon',
// }));
@Component({
  name: 'MapView',
  components: {
    // 'file-column': require('@/components/FileColumn.vue').default,
  }
})
export default class MapView extends Vue {
  map!: Map;
  tracks: Track[] = [];
  selectedTrack: Track | null = null;
  cursor = '';
  select!: Select;
  mounted(){
    this.getUserLocation(11);
    const locate = document.createElement('div');
    locate.className = 'ol-control ol-unselectable locate';
    locate.innerHTML = '<button title="Your location">●</button>';
    locate.addEventListener('click', () => {
        this.getUserLocation();
    });
    this.map = new Map({
      target: 'map',
      layers: [
        new TileLayer({
          source: new OSM(),
          opacity: 0.5,
        }),
      ],
      view: new View({
        center: fromLonLat([10, 60.2]),
        zoom: 8,
        // extent: Extent.createOrUpdate(9, 58, 12, 65),
      })
    });
    this.map.addControl(
        new Control({
          element: locate
        })
    );
    this.fetchTracks();
    this.map.on('pointermove', event => {
      if (event.dragging) {
        return;
      }
      const pixel = this.map.getEventPixel(event.originalEvent);
      this.infoAtPixel(pixel);
    });
    this.select = new Select({
      condition: Click,
      style: null as any,
      hitTolerance: 5,
    });
    this.select.on('select', event => {
      const feature = event.target.getFeatures().array_[0];
      this.selectedTrack = null;
      if (!feature){
        return;
      }
      const selection = this.trackByFeatureId(feature.ol_uid);
      if (selection === this.selectedTrack){
        return;
      }
      this.$nextTick(() => {
        this.selectedTrack = this.trackByFeatureId(feature.ol_uid);
        this.$ga.page(this.selectedTrack.name);
      });
      // const center = this.map.getView().getCenter();
      // this.$nextTick(() => {
      //   this.map.getView().setCenter(center);
      // });
      // document.getElementById('status').innerHTML =
      //   '&nbsp;' +
      //   e.target.getFeatures().getLength() +
      //   ' selected features (last operation selected ' +
      //   e.selected.length +
      //   ' and deselected ' +
      //   e.deselected.length +
      //   ' features)';
    });
    this.map.addInteraction(this.select);
  }
  infoAtPixel(pixel: ol.Pixel) {
    const features = this.map.getFeaturesAtPixel(pixel, {
      hitTolerance: 5,
    });
    if (window.highlightedFeature === features[0]){
      return;
    }
    const prevHighlight = window.highlightedFeature;
    window.highlightedFeature = features[0];
    if (window.highlightedFeature){
      // window.highlightedFeature.changed();
      this.cursor = 'pointer';
    } else {
      this.cursor = '';
    }
    // prevHighlight && prevHighlight.changed();
  }
  fetchTracks(){
    var headers = new Headers([
      ['pragma', 'no-cache'],
      ['cache-control', 'no-cache'],
    ]);
    fetch('/tracks.json', {
      headers,
    }).then(response => response.json()).then(data => this.tracks = data).then(() => {
      this.tracks.forEach(track => {
        const source = new VectorSource({
            url: '/tracks/'+track.gpx,
            format: new GPX(),
        });
        source.once('addfeature', event => {
          this.featureToTrackMap[event.feature.ol_uid.toString()] = track;
          // track.featureId = event.feature.ol_uid.toString();
          console.log('Added mapping for for', event.feature.ol_uid);
          source.once('addfeature', event => {
            this.featureToTrackMap[event.feature.ol_uid.toString()] = track;
            console.log('Added mapping for for', event.feature.ol_uid);
          });
          source.addFeature(this.createMarker(event.feature.getGeometry().getFirstCoordinate()));
        });
        // source.on('addfeature', event => {
        //   this.featureToTrackMap[event.feature.ol_uid.toString()] = track;
        //   // track.featureId = event.feature.ol_uid.toString();
        //   source.addFeature(this.createMarker(event.feature.getGeometry().getFirstCoordinate()));
        //   console.log('Added mapping for for', event.feature.ol_uid);
        //   source.once('addfeature', event => {
        //     this.featureToTrackMap[event.feature.ol_uid.toString()] = track;
        //     console.log('Added mapping for for', event.feature.ol_uid);
        //   });
        // });
        const layer = new VectorLayer({
          source,
          style: this.trackStyle,
        });
        this.map.addLayer(layer);
      });
    });
  }
  featureToTrackMap: {
    [key: string]: Track;
  } = {}
  trackByFeatureId(featureId: string){
    return this.featureToTrackMap[featureId] || nullTrack;
  }
  trackStyle: ol.StyleFunction = (feature, resolution) => {
    return trackStyles[this.trackByFeatureId(feature.ol_uid).difficulty]
  }
  markerStyle: ol.StyleFunction = (feature, resolution) => {
    const track = this.trackByFeatureId(feature.ol_uid);
    return new Style({
      image: markerIcons[track.difficulty],
      text: new Text({
        text: this.trackByFeatureId(feature.ol_uid).name.toUpperCase(),
        backgroundFill: colorFills[track.difficulty],
        textAlign: 'center',
        // offsetX: 25,
        offsetY: -22,
        padding: [7, 5, 2, 8],
        font: '900 8mm sans-serif',
        textBaseline: 'bottom',
        // scale: feature === window.highlightedFeature ? 1.8 : 1.5,
        // scale: 1.5,
        scale: track === this.selectedTrack ? 2 : 1.5,
        fill: colorFills['white'],
        // stroke: markerTextStroke,
      })
    });
  }
  createMarker(coords: ol.Coordinate) {
    const marker = new Feature(new Point(coords));
    marker.setStyle(this.markerStyle.bind(this));
    return marker;
  }
  locationSource: VectorFeature;
  // locationAccuracy: Feature;
  // locationPoint: Feature;
  getUserLocation(maxZoom?: number){
    let initial = true;
    navigator.geolocation.getCurrentPosition(pos => {
          const coords = [pos.coords.longitude, pos.coords.latitude];
          const accuracy = circular(coords, pos.coords.accuracy);
          if (!this.locationSource){
            this.locationSource = new VectorSource();
            this.onUserLocation(this.locationSource);
          } else {
            this.locationSource.clear();
          }
          const locationAccuracy = new Feature({
            geometry: accuracy.transform('EPSG:4326', this.map.getView().getProjection()),

          });
          const locationPoint = new Feature({
            geometry: new Point(fromLonLat(coords)),
          });
          this.locationSource.addFeatures([
            locationAccuracy,
            locationPoint,
          ]);
          if (initial){
              initial = false;
              this.gotoSource(this.locationSource, maxZoom);
          }
    }, function(error) {
        console.error(error);
    }, {
      enableHighAccuracy: true
    });
  }
  onUserLocation(source: VectorSource){
    const layer = new VectorLayer({
      source,
      style: locationStyle,
    });
    this.map.addLayer(layer);
  }
  gotoSource(source: VectorSource, maxZoom=15){
      if (!source.isEmpty()) {
        this.map.getView().fit(source.getExtent(), {
          maxZoom: maxZoom,
          duration: 500
        });
      }
  }
  clearSelection(){
    this.selectedTrack = null;
    this.select.getFeatures().clear();
  }
  onClickDownload(track: Track){
    this.$ga.event('tracks', 'download', track.name);
  }
  onClickStrava(){
    this.$ga.event('tracks', 'strava', track.name);
  }
}
